import xhr from "xhr";
const apihost = process.env.API_HOST;

const http = (method, url, body) => {
    const opts = {
        method: method,
        body: JSON.stringify(body),
        url: apihost + url,
        // If we set json:true then the body is double-stringified, so don't
        headers: {
            "Content-Type": "application/json",
        },
        // withCredentials: true,
    };
    return new Promise((resolve, reject) => {
        xhr(opts, (err, response) => {
            if (err) return reject(err);
            if (response.statusCode !== 200) return reject(response.statusCode);
            let body;
            try {
                // Since we didn't set json: true, we have to parse manually
                body = JSON.parse(response.body);
            } catch (e) {
                return reject(e);
            }
            resolve(body);
        });
    });
};

//获取微信凭证
const getsign = (data) => {
    return http("post", "/wx/getsign", data);
};

//登陆
const login = (data) => {
    // console.log(data);
    return http("post", "/accounts/login", data);
};

//注册
const register = (data) => {
    return http("post", "/accounts/register", data);
};

//获取分享作品
const getShareStuff = () => {
    return http("get", `/getsharestuff`);
};
//获取我的作品
const getMyStuff = (data) => {
    return http("get", `/getmystuff/${data}`);
};
//获取作品信息
const getproject = (data) => {
    return http("get", `/projects/${data}`);
};

//修改作品信息
const updateProject = (projectId, data) => {
    return http("put", `/projects/${projectId}`, data);
};
//删除作品信息
const deleteProject = (data) => {
    return http("delete", `/projects/${data}`);
};
//上传缩略图
const updateProjectThumbnail = (id, blob) => {
    // return http('post', `/internalapi/project/thumbnail/${id}/set/`, blob);
    new Promise((resolve, reject) => {
        fetch(apihost + `/internalapi/project/thumbnail/${id}/set/`, {
            method: "POST",
            headers: {
                "Content-Type": "image/png",
            },
            body: blob,
        })
            .then((response) => {
                // console.log('response', response);
                var body = response.json();
                if (response.status == 200) {
                    return resolve(body);
                }
                return reject(body);
            })
            .catch((err) => reject(err));
    });
};
//验证用户名
const checkusername = (data) => {
    return http("get", "/accounts/checkusername/" + data);
};

//验证昵称
const checknickname = (data) => {
    return http("get", "/accounts/checknickname/" + data);
};

export {
    getsign,
    login,
    register,
    getShareStuff,
    getMyStuff,
    getproject,
    updateProject,
    deleteProject,
    updateProjectThumbnail,
    checkusername,
    checknickname,
};
